export const MAIN_CONFIG = {
  app_name: 'Suivi ITC Maintenance JO et Paralympiques 2024',
  access_perm: 'jo_travaux::ACCESS',
  layers: {
    cassini_tronconditinerairevoie: {
      show_by_default: true,
      view_slug: 'sch',
      props: {
        type: 'line',
        paint: {
          'line-color': '#444444',
        },
      },
    },
    jo_travaux_suivi_itc: {
      show_by_default: true,
      view_slug: 'sch',
      weight: 42,
      props: {
        type: 'fill',
        paint: {
          'fill-opacity': 0.25,
          'fill-color': ['get', 'color'],
        },
      },
    },
  },
}

export default MAIN_CONFIG
